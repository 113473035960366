import { BigNumber } from "ethers";
import { bi } from "./buffer";
import { Buffer20, Buffer24, uint32 } from "./types";

export function toChainAddress(chainId: uint32 | BigNumber | bigint, address: Buffer20) : Buffer24 {
  if(address.byteLength != 20) throw new Error('invalid address');
  const b = Buffer.alloc(24);
  // Only numeric network id's are supported in the chain address, with max of uint32.
  const nr = bi(chainId);
  if(nr <= 0n || nr > 4294967295n) { // 2^32-1
    throw new Error('invalid chainId: '+nr);
  }
  b.writeUInt32BE(Number(nr), 0);
  b.fill(address, 4);
  return b;
}

export function toChainAddressEthers(chainId: number, address: string) : string {
  return '0x'+toChainAddress(BigNumber.from(chainId), Buffer.from(address.substring(2), 'hex')).toString('hex');
}

export function fromChainAddress(chAddr: Buffer24) : [chainId: bigint, addr: Buffer20] {
  return [bi(chAddr.slice(0, 4)), chAddr.slice(4)]; 
}