import { useDataStore } from "../../../../stores/dataStore";
import ReferredTokenMetricsView from "./referredTokenMetricsView";

export function ReferredTokenMetrics({ textFilter }) {
  const [isLoaded] = useDataStore.isLoaded();

  if (!isLoaded) return (<div><h4>Metrics loading...</h4></div>)

  return (
    <div>
      <h2>Metrics per Referred Token</h2>
      <ReferredTokenMetricsView textFilter={textFilter} />
    </div>
  )
}
