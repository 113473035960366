// 32-byte hex-encoded hash of encode(sponsor,rewardToken,referredTokenDefn)

import { PrefixedHexString } from "ethereumjs-util";
import { BigNumber } from "ethers";

// Eg: 0x7a0bb0f2ee16291cee6e20dfa60968dbb7da4d3b3305bcaeedd5412603ef83b3
export type FarmHash = string;

export const ZERO_ADDR = '0x0000000000000000000000000000000000000000';
export const ZERO_HASH = '0x0000000000000000000000000000000000000000000000000000000000000000';

// Full-length hex-encoded (num.toHexString()) string representation of a big number
export type NetworkIdHex = string;

// Zero-stripped rpc encoded network id
export type NetworkIdHexShort = string;

// 32-byte buffer for hashes and 32-byte encoded strings
export interface Buffer32 extends Buffer {};

// 24-byte buffer for chain addresses and token definitions
export interface Buffer24 extends Buffer {};

// 24-byte buffer for chain addresses and token definitions
export interface Buffer20 extends Buffer {};

// // 4-byte usable for chain addresses
// export interface Number32 extends BigNumber {};

// Never used class to force ts compiler to hide the underlying alias type.
class TypeStub {
  constructor() {
  }
}

export type uint32 = bigint | TypeStub;

// 32-byte hex-encoded string
export type Hex32 = string | TypeStub;

// 24-byte hex-encoded string
export type Hex24 = string | TypeStub;

// 20-byte hex-encoded string
export type Hex20 = string | TypeStub;

// Shortened hex encoded number
export type HexNumber = string | TypeStub;
export type HexBuffer = string | TypeStub;

export type BufferTypes = Buffer | Buffer32 | Buffer24 | Buffer20;
export type HexTypes = PrefixedHexString | Hex32 | Hex24 | Hex20;
export type BigIntTypes = bigint | uint32;