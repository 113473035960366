import { ethers } from "ethers";

let provider: ethers.providers.Web3Provider;
export function getProvider() : ethers.providers.Web3Provider {
  if(provider == null) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  }

  return provider;
}

