import { JsonRPCRequest } from "web3/providers";
import { getSession } from "../stores/sessionStore";
import Cache from 'simple-storage-cache';
import stringify from 'json-stable-stringify';
import { getUiContext } from "../stores/uiContextStore";
import rison from 'rison';

export enum RpcRoute {
  rpc = '/v1/rpc',
  recommendations = '/v1/recommendations/rpc',
}

async function jsonRpcRequest(route: RpcRoute, req: JsonRPCRequest) : Promise<any> {
  const [networks] = getSession.networks();
  const [chainId] = getSession.chainId();
  const network = networks[chainId];
  if(!network) throw new Error('no config');
  const oracleUrl = network.oracles[0];

  // CACHE
  const { method, params } = req;
  const cache = new Cache(stringify({ method, params }), getUiContext.cacheMinutes()[0] * 60_000);
  if(network.enableRequestCache) {
    const cached = cache.get();
    if(cached) return cached.data;
  }
  const debugStr = `?${method},${rison.encode(params)}`

  const res = await fetch(oracleUrl+route+debugStr, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(req),
  })
  const d = await res.json();
  if(d.result == undefined) {
    console.error(d, req)
    throw new Error(`${d.error.code}: ${d.error.message}` || 'unknown rpc error')
  }
  cache.update(d.result);
  return d.result;
}

export async function rpcRequest(route: RpcRoute, method: string, params: any[]) : Promise<any> {
  return await jsonRpcRequest(route, {
    id: crypto.randomUUID(),
    jsonrpc: '2.0',
    method,
    params,
  })
}

export async function rpcCall(route: RpcRoute, to: string, data: string) : Promise<any> {
  return await jsonRpcRequest(route, {
    id: crypto.randomUUID(),
    jsonrpc: '2.0',
    method: 'oracle_call_old',
    params: [{
      to,
      data,
    }],
  })
}
