
import { uniq, uniqBy, groupBy, sortBy } from "lodash"
import { buf, hex } from "../../../../../../oracle/src/lib/buffer"
import { fromChainAddress } from "../../../../../../oracle/src/lib/chainAddress"
import { FarmExistsEvent } from "../../../../contracts/referralFarmsV1"
import { calcFarmPositionSize, FarmPositionIncreasedEvent, TokenPosition } from "../../../../oracles/farmPositionsV1"
import { ProofOfRecommendation, ProofOfRecommendationOrigin } from "../../../../oracles/proofOfRecommendations"
import { beautifyBigInt } from "../../../utils/bignumber"
import { Visitor } from "../hooks/useFetchVisitors"
import {TokenListMap} from "../hooks/useFetchTokenList";

interface ReferredTokenMetricRow {
  symbol: string
  referredToken: string
  farmHashes: string[],
  promoterCount: number
  buyerPageVisitorCount: number
  referToEarnVisitorCount: number
  buyToEarnVisitorCount: number
  recommendationCount: number
  buyerWithPositionCount: number
  totalAmount: string
  currentPosition: string
}

const generateInitialMerticPerReferredToken = (farmExists: FarmExistsEvent[], tokenList: TokenListMap): ReferredTokenMetricRow[] => {
  const uniqfarmExists = uniqBy(farmExists, "farmHash")
  const groupedByReferredTokenDefn = groupBy(uniqfarmExists, (row: FarmExistsEvent) => row.referredTokenDefn);

  const rows = Object.entries(groupedByReferredTokenDefn).map(([referredTokenDefn, farms]) => {
    const [_, referredTokenBuf] = fromChainAddress(buf(referredTokenDefn))
    const referredToken = hex(referredTokenBuf);
    
    return {
      symbol: tokenList?.get(referredToken)?.symbol || '',
      referredToken,
      farmHashes: farms.map(farm=> farm.farmHash),
      promoterCount: 0,
      buyerPageVisitorCount: 0,
      referToEarnVisitorCount: 0,
      buyToEarnVisitorCount: 0,
      recommendationCount: 0,
      buyerWithPositionCount: 0,
      totalAmount: '0',
      currentPosition: '0',
    }
  })

  return rows
}

const addPromoterCountToMetric = (rows: ReferredTokenMetricRow[], recommendationOrigins: ProofOfRecommendationOrigin[]) => {
  return rows.map(row => {
    const recommendationOriginsFiltered = recommendationOrigins.filter(o => o.token.toString().toLowerCase() == row.referredToken.toLowerCase())
    return {
      ...row,
      promoterCount: uniq(recommendationOriginsFiltered.map(o => o.signer)).length
    }
  })
}

const addBuyerPageVisitorCountToMetric = (rows: ReferredTokenMetricRow[], visitors: Visitor[]) => {
  return rows.map(row => {
    const buyerPageVisitorFiltered = visitors.filter(visitor => {
      const page = visitor.page.toLowerCase()
      return page.includes("/l/") && page.includes(row.referredToken.slice(2).toString().toLowerCase())
    })
    const referToEarnVisitorFiltered = visitors.filter(visitor => {
      const page = visitor.page.toLowerCase()
      return page.includes("/farms/") && page.includes(row.referredToken.slice(2).toString().toLowerCase())
    })
    const buyToEarnVisitorFiltered = visitors.filter(visitor => {
      const page = visitor.page.toLowerCase()
      return page.includes("/buy-to-earn/") && page.includes(row.referredToken.slice(2).toString().toLowerCase())
    })
    return {
      ...row,
      buyerPageVisitorCount: buyerPageVisitorFiltered.length,
      referToEarnVisitorCount: referToEarnVisitorFiltered.length,
      buyToEarnVisitorCount: buyToEarnVisitorFiltered.length
    }
  })
}

const addRecommendationsToMetric = (rows: ReferredTokenMetricRow[], recommendations: ProofOfRecommendation[]) => {
  return rows.map(row => {
    const recommendationsFiltered = recommendations.filter(o => o.token.toString().toLowerCase() == row.referredToken.toLowerCase())

    return {
      ...row,
      recommendationCount: uniq(recommendationsFiltered.map(o => o.signer)).length
    }
  })
}

const addTotalAmountAndBuyerWithPositionCountToMetric = (
  rows: ReferredTokenMetricRow[],
  farmPositionIncreasedEvents: FarmPositionIncreasedEvent[],
  units?: number,
  numberPrecision?: number
) => rows.map((row) => {
  const increasedPositions = farmPositionIncreasedEvents.filter((o) => {
    const [_, tokenBuf] = fromChainAddress(buf(o.token));
    const tokenAddr = hex(tokenBuf);

    return tokenAddr == row.referredToken;
  });
  const uniqPositionIncreasedEvents = uniqBy(increasedPositions, "time")

  const totalAmount = uniqPositionIncreasedEvents.reduce((acc, o) => acc + o.value, 0n);

  return {
    ...row,
    totalAmount: beautifyBigInt(totalAmount, units, numberPrecision),
    // uniq buyers
    buyerWithPositionCount: uniq(uniqPositionIncreasedEvents.map(event => event.holder)).length
  };
});

const addCurrentPositionToMetric = (
  rows: ReferredTokenMetricRow[],
  tokenPositions: TokenPosition[],
  units?: number,
  numberPrecision?: number
) => rows.map((row) => {
  const tokenPositionsFiltered = tokenPositions.filter((o) => {
    const [_, tokenBuf] = fromChainAddress(buf(o.token));
    const tokenAddr = hex(tokenBuf);

    return tokenAddr == row.referredToken;
  });

  const tokenPositionsGroupByFarmHash = groupBy(tokenPositionsFiltered, "farmHash")
  const res = Object.entries(tokenPositionsGroupByFarmHash).map(([farmHash, tokenPositions]) => {
    return {
      farmHash,
      totalTokenPositions: calcFarmPositionSize(tokenPositions, farmHash)
    }
  })
  return {
    ...row,
    currentPosition: res?.[0]?.totalTokenPositions ? beautifyBigInt(res[0].totalTokenPositions, units, numberPrecision) : "0",
  };
});

interface IGenerateMetricRows {
  farmExists: FarmExistsEvent[],
  recommendationOrigins?: ProofOfRecommendationOrigin[],
  recommendations?: ProofOfRecommendation[],
  farmPositionIncreasedEvents?: FarmPositionIncreasedEvent[],
  tokenPositions?: TokenPosition[],
  visitors?: Visitor[],
  units?: number,
  numberPrecision?: number,
  tokenList: TokenListMap
}

export const generateMetricRows = (props: IGenerateMetricRows): ReferredTokenMetricRow[] => {
  let rows = generateInitialMerticPerReferredToken(props.farmExists, props.tokenList)

  if (props.recommendationOrigins) {
    rows = addPromoterCountToMetric(rows, props.recommendationOrigins)
  }

  if (props.visitors?.length) {
    rows = addBuyerPageVisitorCountToMetric(rows, props.visitors)
  }

  if (props.recommendations) {
    rows = addRecommendationsToMetric(rows, props.recommendations)
  }
  
  if (props.farmPositionIncreasedEvents) {
    rows = addTotalAmountAndBuyerWithPositionCountToMetric(rows, props.farmPositionIncreasedEvents, props.units, props.numberPrecision)
  }

  if (props.tokenPositions) {
    rows = addCurrentPositionToMetric(rows, props.tokenPositions)
  }

  return sortBy(rows, ['referredToken'])
}