
import { uniq, sortBy, uniqBy, groupBy } from "lodash"
import { buf, hex } from "../../../../../../oracle/src/lib/buffer"
import { fromChainAddress } from "../../../../../../oracle/src/lib/chainAddress"
import { FarmPositionDecreasedEvent, FarmPositionIncreasedEvent } from "../../../../oracles/farmPositionsV1"
import { ProofOfRecommendation, ProofOfRecommendationOrigin } from "../../../../oracles/proofOfRecommendations"
import { beautifyBigInt } from "../../../utils/bignumber"
import { Visitor } from "../hooks/useFetchVisitors"
import { TokenListMap } from "../hooks/useFetchTokenList";

interface ReferredTokenMetricRow {
  symbol: string,
  promoter: string,
  referredToken: string
  buyerPageVisitorCount: number
  recommendationCount: number
  recommendationSigners: string[]
  buyerWithPositionCount: number
  buyers: string[]
  totalAmount: string
  currentPosition: string
}

const generateInitialMerticPerPromoter = (recommendationOrigins: ProofOfRecommendationOrigin[], tokenList: TokenListMap): ReferredTokenMetricRow[] => {
  let rows: ReferredTokenMetricRow[] = []
  let recommendationOriginsMap = new Map<string, ProofOfRecommendationOrigin>()
  
  recommendationOrigins.forEach(recommendation => {
    recommendationOriginsMap.set(`${recommendation.signer.toString()}-${recommendation.token.toString()}`, recommendation)
  })

  recommendationOriginsMap.forEach(recommendation => {
    const referredToken = recommendation.token.toString();
    rows.push({
      symbol: tokenList?.get(referredToken)?.symbol || '',
      promoter: recommendation.signer.toString(),
      referredToken,
      buyerPageVisitorCount: 0,
      recommendationCount: 0,
      recommendationSigners: [],
      buyerWithPositionCount: 0,
      buyers: [],
      totalAmount: '0',
      currentPosition: '0',
   })
  })

  return rows
}

const addBuyerPageVisitorCountToMetric = (rows: ReferredTokenMetricRow[], visitors: Visitor[]) => {
  return rows.map(row => {
    const referredToken = row.referredToken.slice(2).toString().toLowerCase()
    const promoter = row.promoter.slice(2).toString().toLowerCase()
    const partLink = `${referredToken}${promoter}`
    
    const visitorsFiltered = visitors.filter(visitor => visitor.page.toLowerCase().includes(partLink))

    return {
      ...row,
      buyerPageVisitorCount: visitorsFiltered.length
    }
  })
}

const addRecommendationsToMetric = (rows: ReferredTokenMetricRow[], recommendations: ProofOfRecommendation[]) => {
  return rows.map(row => {
    const recommendationsFiltered = recommendations.filter(o => o.referrer.toString().toLowerCase() == row.promoter.toLowerCase() && o.token.toString().toLowerCase() == row.referredToken.toLowerCase())
    const recommendationSigners = recommendationsFiltered.map(recommendation => recommendation.signer.toString().toLowerCase())
    return {
      ...row,
      recommendationSigners: uniq(recommendationSigners),
      recommendationCount: uniq(recommendationsFiltered.map(o => o.signer)).length
    }
  })
}

const addTotalAmountAndBuyerWithPositionCountToMetric = (
  rows: ReferredTokenMetricRow[],
  farmPositionIncreasedEvents: FarmPositionIncreasedEvent[],
  units?: number,
  numberPrecision?: number
) => rows.map((row) => {
  const increasedPositions = farmPositionIncreasedEvents.filter((event) => {
    const [_, tokenBuf] = fromChainAddress(buf(event.token));
    const tokenAddr = hex(tokenBuf);

    return tokenAddr == row.referredToken && row.recommendationSigners.includes(event.holder.toString().toLowerCase())
  });

  const uniqPositionIncreasedEvents = uniqBy(increasedPositions, "time")

  const totalAmount = uniqPositionIncreasedEvents.reduce((acc, o) => acc + o.value, 0n);

  return {
    ...row,
    totalAmount: beautifyBigInt(totalAmount, units, numberPrecision),
    // uniq buyers
    buyers:  uniq(uniqPositionIncreasedEvents.map(event => event.holder.toString())),
    buyerWithPositionCount: uniq(uniqPositionIncreasedEvents.map(event => event.holder)).length
  };
});

const addCurrentPositionToMetric = (
  rows: ReferredTokenMetricRow[],
  farmPositionIncreasedEvents: FarmPositionIncreasedEvent[],
  farmPositionDecreasedEvents: FarmPositionDecreasedEvent[],
  units?: number,
  numberPrecision?: number
) => rows.map((row) => {
  const increasedPositions = farmPositionIncreasedEvents.filter((event) => {
    const [_, tokenBuf] = fromChainAddress(buf(event.token));
    const tokenAddr = hex(tokenBuf);

    return tokenAddr == row.referredToken && row.recommendationSigners.includes(event.holder.toString().toLowerCase())
  });


  const decreasedPositions = farmPositionDecreasedEvents.filter((event) => {
    const [_, tokenBuf] = fromChainAddress(buf(event.token));
    const tokenAddr = hex(tokenBuf);

    return tokenAddr == row.referredToken && row.recommendationSigners.includes(event.holder.toString().toLowerCase())
  });

  const uniqIncreasedPositions = uniqBy(increasedPositions, "time")
  const uniqDecreasedPositions = uniqBy(decreasedPositions, "time")

  const positionIncreasedGroupByHolder = groupBy(uniqIncreasedPositions, "holder")
  const positionDecreasedGroupByHolder = groupBy(uniqDecreasedPositions, "holder")

  let currentPosition = 0n
  Object.entries(positionIncreasedGroupByHolder).forEach(([holder, holderPositionIncreased]) => {
    const holderPositionDecreased = positionDecreasedGroupByHolder[holder] || []
    const holderAmount = holderPositionIncreased.reduce((acc, o) => acc + o.value, 0n) - holderPositionDecreased.reduce((acc, o) => acc + o.value, 0n);
    const holderCurrentPosition = holderAmount < 0n ? 0n : holderAmount
    currentPosition += holderCurrentPosition
  })

  return {
    ...row,
    currentPosition: beautifyBigInt(currentPosition, units, numberPrecision),
  };
});

interface IGenerateMetricRows {
  recommendationOrigins: ProofOfRecommendationOrigin[]
  recommendations?: ProofOfRecommendation[]
  farmPositionIncreasedEvents?: FarmPositionIncreasedEvent[]
  farmPositionDecreasedEvents?: FarmPositionDecreasedEvent[]
  visitors?: Visitor[]
  units?: number,
  numberPrecision?: number,
  tokenList: TokenListMap
}

export const generateMetricRows = (props: IGenerateMetricRows): ReferredTokenMetricRow[] => {
  let rows = generateInitialMerticPerPromoter(props.recommendationOrigins, props.tokenList)

  if (props.recommendations) {
    rows = addRecommendationsToMetric(rows, props.recommendations)
  }

  if (props.farmPositionIncreasedEvents) {
    rows = addTotalAmountAndBuyerWithPositionCountToMetric(rows, props.farmPositionIncreasedEvents, props.units, props.numberPrecision)
  }

  if (props.visitors?.length) {
    rows = addBuyerPageVisitorCountToMetric(rows, props.visitors)
  }

  if (props.farmPositionIncreasedEvents && props.farmPositionDecreasedEvents) {
    rows = addCurrentPositionToMetric(rows, props.farmPositionIncreasedEvents, props.farmPositionDecreasedEvents, props.units, props.numberPrecision)
  }
  
  return sortBy(rows, ['referredToken'])
}