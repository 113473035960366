import { PrefixedHexString } from "ethereumjs-util";
import { BigNumber } from "ethers";
import { hexStripZeros } from "ethers/lib/utils";
import { JsonRpcRequest } from "../rpc/types";
import { hex } from "./buffer";
import { HexBuffer } from "./types";
import { monotonicFactory }  from 'ulid';
const ulid = monotonicFactory();

type EncodedRpcResponse = PrefixedHexString | boolean | EncodedRpcResponse[];

export function rpcEncode(n: RpcValue) : EncodedRpcResponse {
  if(Buffer.isBuffer(n)) {
    return hex(n);
  }

  // Examples: 0xljkdsfklsdf..., newHeads
  if(typeof n === 'string') {
    return n;
  }

  if (typeof n === 'boolean') {
    return n
  }

  if (typeof n === 'number' || typeof n === 'bigint' || n instanceof BigNumber || (n as any)?._isBigNumber == true)  {
    const stripped = hexStripZeros(hex(n));
    if(stripped === '0x') return '0x0';
    return stripped;
  }

  if(n instanceof Array) {
    return n.map(rpcEncode);
  }

  debugger;
  console.error({ n });
  throw new Error('unsupported');
}

export type RpcValue = BigNumber | number | PrefixedHexString | Buffer | boolean | bigint | HexBuffer | RpcValue[];

export function toJsonRpcStructure(method: string, params?: any[]) : JsonRpcRequest {
  return {
    jsonrpc: '2.0',
    method,
    params,
    id: ulid(),
  }
}

export function toJsonRpcRequest(method: string, params?: RpcValue[]) : JsonRpcRequest {
  return toJsonRpcStructure(method, params && params.map(p => rpcEncode(p)));
}