"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SimpleStorageCache = /** @class */ (function () {
    function SimpleStorageCache(key, milliseconds, storage) {
        if (storage === void 0) { storage = localStorage; }
        this.milliseconds = milliseconds;
        this.storage = storage;
        this.key = "ssc-" + key;
    }
    SimpleStorageCache.prototype.update = function (data) {
        this.storage.setItem(this.key, JSON.stringify({
            data: data,
            expiration: Date.now() + this.milliseconds,
        }));
    };
    SimpleStorageCache.prototype.get = function () {
        var cache = this.storage.getItem(this.key);
        if (!cache) {
            return null;
        }
        var parsedCache = JSON.parse(cache);
        if (Date.now() >= parsedCache.expiration) {
            return null;
        }
        return parsedCache;
    };
    return SimpleStorageCache;
}());
exports.default = SimpleStorageCache;
