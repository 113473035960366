import { defaultAbiCoder } from "@ethersproject/abi";
import { BigNumber, BytesLike } from "ethers";
import { parseBytes32String } from "ethers/lib/utils";
import { b32str, buf, hex } from "../lib/buffer";
import { keccak256 } from "../lib/hash";
import { Buffer32 } from "../lib/types";

// Values which are not configured remotely we store as clean buffers
export class ChildStateNamespace {
  static readonly oracle = new ChildStateNamespace(Buffer.from('oracle'));
  static readonly sharedV1 = new ChildStateNamespace(Buffer.from('sharedV1'));
  static readonly confirmationV1 = new ChildStateNamespace(Buffer.from('confirmationV1'));
  static readonly immuResolveV1 = new ChildStateNamespace(Buffer.from('immuResolveV1'));
  static readonly entitlementsV1 = new ChildStateNamespace(Buffer.from('entitlementsV1'));
  static readonly referralFarmsV1 = new ChildStateNamespace(Buffer.from('referralFarmsV1'));
  static readonly farmPositionsV1 = new ChildStateNamespace(Buffer.from('farmPositionsV1'));
  static readonly farmTokenSizeV1 = new ChildStateNamespace(Buffer.from('farmTokenSizeV1'));
  static readonly developerV1 = new ChildStateNamespace(Buffer.from('developerV1'));

  // private to disallow creating other instances of this type
  private constructor(public readonly prefix: Buffer) {
  }

  toString() {
    return hex(this.prefix);
  }
}

// Chain-configurable properties we store identically as they are referenced on chain.
// Since enums don't support complex types, we manage it through this class.
export class AuthorityConfig {
  
  // WARNING: remember to add a handler to oracleAuthorityV1Reactor when adding values here (keep this comment)

  static readonly authority  = new AuthorityConfig([b32str('authority')], '(uint256 chainId, address contract)');
  static readonly settingValidator  = new AuthorityConfig([b32str('settingValidator')], 'address');
  static readonly pacemaker  = new AuthorityConfig([b32str('pacemaker')], 'uint256');
  static readonly networks  = new AuthorityConfig([b32str('networks')], '(uint256 chainId, bytes32 parentHash)[]');
  static readonly referralFarmsV1  = new AuthorityConfig([b32str('referralFarmsV1')], 'bytes24');
  static readonly confirmationsV1  = new AuthorityConfig([b32str('confirmationsV1')], 'address');
  static readonly erc20PostnIncreaseOrigins  = new AuthorityConfig([b32str('erc20PostnIncreaseOrigins')], 'bytes24[]');
  static readonly developerSupportV1  = new AuthorityConfig([b32str('developerSupportV1')], 'bytes24');
  static readonly confirmStrategyV1  = new AuthorityConfig([b32str('confirmStrategyV1')], 'bytes32');

  // private to disallow creating other instances of this type
  public readonly pathKey: Buffer;

  private constructor(public readonly path: Buffer32[], public readonly abiFormat: string) {
    this.pathKey = hashPath(path);
  }

  toString() {
    return hex(this.pathKey);
  }
}

export function toHumanPath(path: Buffer32[]) : string {
  return path.map(b => parseBytes32String(hex(b))).join('.');
}

export function hashPath(path: Buffer32[]) : Buffer32 {
  return keccak256(buf(defaultAbiCoder.encode(['bytes32[]'], [path])));
}


// // TODO fork should enforce code version via integrity hashes (think about system which supports build integrity hashes for different arcitectures). The idea is that the system should throw if you're not running the correct version of the code. This is also a protection for oracle operators getting slashed without bad intent.

interface StateNetwork {
  chainId: BigNumber;

  // Parent offset/root/start (block) hash where the bundler will start from on a fresh network
  parentHash: BytesLike;
}
export type StateNetworks = Array<StateNetwork>;

