import {createContext, FC, useCallback, useContext, useState} from "react";
import { EvmAddress } from '../../../../../chains/lib/types'

interface IWalletContext {
  userAddress: EvmAddress;
  connect: () => Promise<any>;
  connected: boolean;
  error?: string;
}

const WalletContext = createContext({
  userAddress: "",
} as IWalletContext);

export const WalletProvider: FC = ({ children }) => {
  const [userAddress, setUserAddress] = useState("");
  const [error, setError] = useState<string | undefined>();

  const connect = useCallback(async () => {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      setUserAddress(accounts[0]);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  }, [setUserAddress]);

  return (
    <WalletContext.Provider
      value={{
        userAddress,
        connect,
        connected: window.ethereum.isConnected(),
        error,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = () => useContext(WalletContext);
