import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import { getSession } from "../stores/sessionStore";

export function fmtTime(v: number | BigInt) : string {
  const [timeFormat] = getSession.timeFormat();
  let num = Number(v);
  if(num < 10000000000) {
    num = num * 1000;
  }
  return dayjs.utc(num).format(timeFormat);
}

export async function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}