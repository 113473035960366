import { Contract, ethers, Event } from "ethers";
import { defaultAbiCoder, parseBytes32String } from "ethers/lib/utils";
import { useState } from "react";
import useSWR from "swr/immutable";
import { buf, hex } from "../../../oracle/src/lib/buffer";
import { fromChainAddress } from "../../../oracle/src/lib/chainAddress";
import { Hex20, Hex32, HexBuffer } from "../../../oracle/src/lib/types";
import { getProvider } from "../helpers";
import { getSession } from "../stores/sessionStore";

const iface = new ethers.utils.Interface([
  `function getFarmDepositRemaining(bytes32 farmHash) view returns (uint256)`,
  `function getFarmConfirmationRewardMax(bytes32 farmHash) view returns (uint256 reward)`,

  `event FarmExists(address indexed sponsor, bytes24 indexed rewardTokenDefn, bytes24 indexed referredTokenDefn, bytes32 farmHash)`,
  `event FarmMetastate(bytes32 indexed farmHash, bytes32 indexed key, bytes value)`,
  `event RewardsHarvested(address indexed caller, bytes24 indexed rewardTokenDefn, bytes32 indexed farmHash, uint128 value, bytes32 leafHash)`,
  "event FarmDepositIncreased(bytes32 indexed farmHash, uint128 delta)",
  "event FarmDepositDecreaseRequested(bytes32 indexed farmHash, uint128 value, uint128 confirmation)",
  "event FarmDepositDecreaseClaimed(bytes32 indexed farmHash, uint128 delta)",
]);

export function getReferralFarmsV1() : Contract {
  const [chaddr] = getSession.farms();
  const [chainId, addr] = fromChainAddress(buf(chaddr));
  return new ethers.Contract(hex(addr), iface, getProvider());
}

export interface FarmExistsEvent {
  sponsor: string;
  rewardTokenDefn: string;
  referredTokenDefn: string;
  farmHash: string;
  blockNumber: number;
  event: Event;
}

export async function getFarmExistsEvents() : Promise<FarmExistsEvent[]> {
  const contract = getReferralFarmsV1();
  const events = await contract.queryFilter(contract.filters.FarmExists());

  return events.map(e => {
    return {
      sponsor: e.args?.sponsor,
      rewardTokenDefn: e.args?.rewardTokenDefn,
      referredTokenDefn: e.args?.referredTokenDefn,
      farmHash: e.args?.farmHash,
      blockNumber: e.blockNumber,
      event: e,
    }
  });
}

export interface FarmDepositIncreasedEvent {
  farmHash: Hex32;
  delta: bigint;
  event: Event;
} 

export async function getFarmDepositIncreasedEvents() : Promise<FarmDepositIncreasedEvent[]> {
  const contract = getReferralFarmsV1();
  const events = await contract.queryFilter(contract.filters.FarmDepositIncreased());

  return events.map(e => {
    return {
      farmHash: e.args.farmHash,
      delta: e.args.delta.toBigInt(),
      event: e,
    }
  });
}

export interface FarmMetastateEvent {
  farmHash: Hex32;
  key: Hex32;
  value: HexBuffer;
  keyParsed: string;
  valueParsed: string;
  event: Event;
} 

export async function getFarmMetastateEvents() : Promise<FarmMetastateEvent[]> {
  const contract = getReferralFarmsV1();
  const events = await contract.queryFilter(contract.filters.FarmMetastate());

  return events.map(e => {
    const keyParsed = parseBytes32String(e.args.key)
    let valueParsed = null;
    if(keyParsed === 'confirmationReward') {
      const [rewardBig, activationConfirmationBig] = defaultAbiCoder.decode(['uint128','uint128'], e.args.value);
      valueParsed = `${rewardBig.toString()} - ${activationConfirmationBig.toString()}`
    }
    if(keyParsed === 'rewardsLockTime') {
      const [rewardsLockTime] = defaultAbiCoder.decode(['uint64'], e.args.value);
      valueParsed = `${rewardsLockTime.toString()}`
    }
    return {
      farmHash: e.args.farmHash,
      key: e.args.key,
      value: e.args.value,
      keyParsed,
      valueParsed, 
      event: e,
    }
  });
}

export interface RewardsHarvestedEvent {
  caller: Hex20;
  rewardTokenDefn: Hex24;
  farmHash: Hex32;
  value: bigint;
  leafHash: Hex32;
  event: Event;
} 

export async function getRewardsHarvestedEvents() : Promise<RewardsHarvestedEvent[]> {
  const contract = getReferralFarmsV1();
  const events = await contract.queryFilter(contract.filters.RewardsHarvested());

  return events.map(e => {
    return {
      caller: e.args.caller,
      rewardTokenDefn: e.args.rewardTokenDefn,
      farmHash: e.args.farmHash,
      value: e.args.value.toBigInt(),
      leafHash: e.args.leafHash,
      event: e,
    }
  });
}

export function calcFarmDepositSum(
  farmDepositIncreased: FarmDepositIncreasedEvent[],
  farmDepositDecreaseClaimed: FarmDepositDecreaseClaimedEvent[] = [],
  farmHash: string,
): bigint {
  let sum = BigInt(0)

  const eventsFarmDepositIncreased = farmDepositIncreased.filter((e) => e.farmHash === farmHash)
  for (const event of eventsFarmDepositIncreased) {
      sum += event.delta
  }

  const eventsFarmDepositDecreaseRequested = farmDepositDecreaseClaimed.filter((e) => e.farmHash === farmHash)
  for (const event of eventsFarmDepositDecreaseRequested) {
      sum -= event.delta
  }

  return sum
}

export interface FarmDepositDecreaseRequestedEvent {
  farmHash: Hex32;
  value: bigint;
  confirmation: bigint;
  event: Event;
}

export async function farmDepositDecreaseRequestedEventsFetcher() : Promise<FarmDepositDecreaseRequestedEvent[]> {
  const contract = await getReferralFarmsV1();
  const events = await contract.queryFilter(contract.filters.FarmDepositDecreaseRequested());
  return events.map(e => {
    return {
      farmHash: e.args.farmHash,
      value: e.args.value.toBigInt(),
      confirmation: e.args.confirmation.toBigInt(),
      event: e,
    }
  });
}

export function useFarmDepositDecreaseRequestedEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['farms.farmDepositDecreaseRequested'] : null, farmDepositDecreaseRequestedEventsFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data, 
    error,
  }
}


export interface FarmDepositDecreaseClaimedEvent {
  farmHash: Hex32;
  delta: bigint;
  event: Event;
}

export async function farmDepositDecreaseClaimedEventsFetcher() : Promise<FarmDepositDecreaseClaimedEvent[]> {
  const contract = await getReferralFarmsV1();
  const events = await contract.queryFilter(contract.filters.FarmDepositDecreaseClaimed());
  return events.map(e => {
    return {
      farmHash: e.args.farmHash,
      delta: e.args.delta.toBigInt(),
      event: e,
    }
  });
}

export function useFarmDepositDecreaseClaimedEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['farms.farmDepositDecreaseClaimed'] : null, farmDepositDecreaseClaimedEventsFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data, 
    error,
  }
}
