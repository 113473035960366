import createStore from 'teaful';

interface UiContext {
  showFarms: boolean;
  showExplorer: boolean;
  showArch: boolean;
  showTools: boolean;
  showBlocks: boolean;
  showTransfers: boolean;
  showDevtools: boolean;
  
  // OR hashes to filter by, can be used dynamically
  filterHashes: string[];

  cacheMinutes: number;

  numberPrecision: number;
  units: number;
  timeFormat: string;
}

// getStore can be used in services, useStore works as expected.
export const { useStore: useUiContext, getStore: getUiContext, withStore: withUiContext, setStore: setUiContext } = createStore<UiContext>({
  showFarms: false,
  showExplorer: false,
  showArch: false,
  showTools: false,
  showBlocks: false,
  showTransfers: false,
  showDevtools: false,

  filterHashes: [],

  numberPrecision: 6,
  cacheMinutes: 60,
  units: 18,
  timeFormat: 'YYYY-MM-DDTHH:mm:ssZ',
});