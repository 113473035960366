import { groupBy } from "lodash";
import { calcFarmDepositSum, FarmExistsEvent } from "../../contracts/referralFarmsV1";
import { ValidatedEntitlementEvent } from "../../hooks/types";
// import { FarmPositionChangeEvent } from '../../oracles/farmPositionsV1';
import { useFilteredData } from "../../hooks/data";
import { ConfirmationEvent } from '../../oracles/confirmationsV1';
import { FarmPositionDecreasedEvent, FarmPositionIncreasedEvent, TokenPosition, useFarmPositionDecreasedEvents, useFarmPositionIncreasedEvents } from "../../oracles/farmPositionsV1";
import { FarmTrackedRewardValue } from '../../oracles/referralFarmsV1';
import { shareValue } from "./shareValue";
import { EntitlementEvent, LockedEntitlementEvent } from "../../oracles/entitlementsV1";

export function groupDepositsByUniqReferredAndRewardTokens(
    farmExistsIdx,
    farmDepositIncreased = [],
    farmDepositDecreaseClaimed,
    farmsTrackedRewardValue: FarmTrackedRewardValue[],
) {
    if (!farmExistsIdx) return []

    const farms = {}
    Object.entries(farmExistsIdx).forEach(([farmHash, farm]) => {
        farms[farmHash] = {
            ...farm,
            totalDeposit: calcFarmDepositSum(farmDepositIncreased, farmDepositDecreaseClaimed, farmHash),
            farmTrackedRewardValue:
                farmsTrackedRewardValue?.find((val) => val.farmHash === farmHash)?.reward || BigInt(0),
        }
    })

    const grouped = groupBy(Object.values(farms), (farm) => {
        return `${farm.referredTokenDefn}+${farm.rewardTokenDefn}`
    })

    return Object.entries(grouped).map(([, farm]) => {
        return {
            referredTokenDefn: farm[0].referredTokenDefn,
            rewardTokenDefn: farm[0].rewardTokenDefn,
            farmHashes: farm.map(({ farmHash }) => farmHash),
            sponsors: farm.map(({ sponsor }) => sponsor),
            totalDeposit: farm.reduce(
                (previousValue, currentValue) => previousValue + currentValue.totalDeposit,
                BigInt(0),
            ),
            farmsTrackedRewardValue: farm.reduce(
                (previousValue, currentValue) => previousValue + currentValue.farmTrackedRewardValue,
                BigInt(0),
            ),
        }
    })
}

export function groupEntitlementsByUniqReferredAndRewardTokens(
    entitlements: ValidatedEntitlementEvent[],
    farmExistsIdx: Record<string, FarmExistsEvent>,
) {
    if (!entitlements.length) return []

    const entitlementsWithFarm = entitlements.map((entitlement) => {
        return {
            ...entitlement,
            farm: farmExistsIdx[entitlement.farmHash as string],
        }
    })

    const grouped = groupBy(entitlementsWithFarm, (entitlement) => {
        return `${entitlement.farm?.referredTokenDefn}+${entitlement.farm?.rewardTokenDefn}+${entitlement.confirmation}`
    })

    return Object.entries(grouped).map(([, entitlement]) => {
        const confirmation = entitlement[0].confirmation
        const farmHashes = entitlement.map(({ farmHash }) => farmHash)
        const sumPrevPeriodEntitlements = entitlements
            .filter(
                (entitlement) => farmHashes.includes(entitlement.farmHash) && entitlement.confirmation < confirmation,
            )
            .reduce((previousValue, currentValue) => previousValue + currentValue.rewardValue, BigInt(0))

        return {
            referredTokenDefn: entitlement[0].farm?.referredTokenDefn,
            rewardTokenDefn: entitlement[0].farm?.rewardTokenDefn,
            farmHashes: [...new Set(farmHashes)],
            confirmation,
            entitlees: entitlement.map(({ entitlee }) => entitlee),
            sumCurrentPeriodEntitlements: entitlement.reduce(
                (previousValue, currentValue) => previousValue + currentValue.rewardValue,
                BigInt(0),
            ),
            sumPrevPeriodEntitlements,
        }
    })
}

export function getFarmPositionChangeWithConfirmation(
    farmPositionChangeEvents: FarmPositionIncreasedEvent[] | FarmPositionDecreasedEvent[],
    confirmationEvents: ConfirmationEvent[],
) {
    return farmPositionChangeEvents.map((fEvent) => {
        const confirmation = confirmationEvents.find((cEvent, i) => {
            const blockNr = Number(fEvent.log.blockNumber)
            const confirmationBlockNr = Number(cEvent.log.blockNumber)
            const prevConfirmationBlockNr = Number(confirmationEvents[i - 1]?.log.blockNumber)

            return (
                blockNr <= confirmationBlockNr && (prevConfirmationBlockNr ? blockNr > prevConfirmationBlockNr : true)
            )
        })

        return {
            ...fEvent,
            blockNr: Number(fEvent.log.blockNumber),
            confirmationNr: confirmation?.number,
        }
    })
}

export function useFarmPositionIncreasedWithConfirmationEvents() {
    const { confirmation } = useFilteredData();
    const { ensure, data: farmPositionIncreased, error } = useFarmPositionIncreasedEvents();
    const data: FarmPositionIncreasedEvent[] = (confirmation && farmPositionIncreased && getFarmPositionChangeWithConfirmation(farmPositionIncreased, confirmation)) 
      || [];

    return {
        ensure,
        data,
        error,
    }
}

export function useFarmPositionDecreasedWithConfirmationEvents() {
    const { confirmation } = useFilteredData();
    const { ensure, data: farmPositionDecreased, error } = useFarmPositionDecreasedEvents();
    const data: FarmPositionDecreasedEvent[] = (confirmation && farmPositionDecreased && getFarmPositionChangeWithConfirmation(farmPositionDecreased, confirmation)) 
      || [];

    return {
        ensure,
        data,
        error,
    }
}

export function aggregateTokenPositions(
    tokenPositions?: TokenPosition[],
    units: number = 18,
    numberPrecision: number = 6,
) { 
    if (!tokenPositions?.length) return;

    const sumByFarmHash = tokenPositions.reduce((acc, value, i) => {
      const k = `${value.farmHash}`;
      if(!acc[k]) acc[k] = 0n;
      acc[k] += value.size;
      return acc;
    }, {});

    return tokenPositions.map(position => {
        const totalFarmHashPosition = sumByFarmHash[position.farmHash] || 0n
        return {
            ...position,
            totalPositionShare: `${shareValue(position.size, totalFarmHashPosition, units, numberPrecision)}%`
        }
    })
}
function sumEntitlements(entitlementEvents) {
  return entitlementEvents.reduce((acc, v, i) => {
    const k = `.${v.confirmation}${v.farmHash}`;
    if (!acc[k]) acc[k] = 0n;
    acc[k] += v.rewardValue;
    return acc;
  }, {});
}

export function aggregateLockedEntitlements(
  lockedEntitlementEvents?: LockedEntitlementEvent[],
  units = 18,
  numberPrecision = 6,
) {
  if (!lockedEntitlementEvents?.length) return;

  const sumsByConfirmationAndFarmHash = sumEntitlements(lockedEntitlementEvents)

  return lockedEntitlementEvents.map((e) => {
    const sumByConfirmationAndFarmHash = sumsByConfirmationAndFarmHash[`.${e.confirmation}${e.farmHash}`];
    return {
      ...e,
      sumByConfirmationAndFarmHash,
      totalEntitlementShare: `${shareValue(e.rewardValue, sumByConfirmationAndFarmHash, units, numberPrecision)}%`,
    };
  });
}

export function aggregateEntitlements(
  entitlementEvents?: EntitlementEvent[],
  units = 18,
  numberPrecision = 6,
) {
  if (!entitlementEvents?.length) return;
    
  const sumsByConfirmationAndFarmHash = sumEntitlements(entitlementEvents)

  return entitlementEvents.map((e) => {
    const sumByConfirmationAndFarmHash = sumsByConfirmationAndFarmHash[`.${e.confirmation}${e.farmHash}`];
    return {
      ...e,
      sumByConfirmationAndFarmHash,
      totalEntitlementShare: `${shareValue(e.rewardValue, sumByConfirmationAndFarmHash, units, numberPrecision)}%`,
    };
  });
}