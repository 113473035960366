import assert from 'assert';
import { BN, PrefixedHexString } from 'ethereumjs-util';
import { BigNumber, Bytes } from 'ethers';
import { formatBytes32String } from 'ethers/lib/utils';
import { BigIntTypes, Buffer24, Buffer32, BufferTypes, HexNumber, HexTypes, ZERO_HASH } from './types';

if(!assert) debugger; // Leave this to block vscode optimize import to remove the import..

export const ZERO_HASH_BUFFER = Buffer.from(ZERO_HASH.substring(2), 'hex');

export type ByteBufferLike = Bytes | PrefixedHexString | Uint8Array | Buffer;

function toEvenLength(str: string) : string {
  if(str.length % 2 !== 0) {
    return '0' + str;
  }
  return str;
}

const bn0 = BigNumber.from(0);

// Fast convert internal types to Buffer
// Ensure we only convert values if we need them
export function buf(b: BufferTypes | Bytes | Uint8Array | HexTypes | number | BigIntTypes | BigNumber) : Buffer {
  if(b == null) return undefined;
  
  if(Buffer.isBuffer(b)) return b; 
  
  if(b instanceof Uint8Array) return Buffer.from(b);
  
  if(typeof b === 'number') {
    if(b < 0) {
      throw new Error('- unsupported');
    }
    return Buffer.from(BigNumber.from(b).toHexString().substring(2), 'hex'); 
  }

  if(typeof b === 'bigint') {
    if(b < 0n) {
      throw new Error('- unsupported');
    }
    return Buffer.from(toEvenLength(b.toString(16)), 'hex')
  }

  if(typeof b === 'string') {
    if(b.substring(0,2) !== '0x') throw new Error('unsupported');
    const hex = b.substring(2);
    if(hex.length % 2 !== 0 || !isHexString(b)) { // Buffer.from(hex, 'hex') will throw on invalid hex, should we do it?
      throw new Error('invalid hexable string');
    }

    return Buffer.from(hex, 'hex');
  }

  // This should return the most compact buffer version without leading zeros, so safe for RLP encodings.
  if(BigNumber.isBigNumber(b)) {
    if(b.lt(bn0)) {
      throw new Error('- unsupported');
    }
    return Buffer.from((b as BigNumber).toHexString().substring(2), 'hex');
  }

  debugger
  console.error(b);
  throw new Error('unsupported: ');
}

function isHexString(value: string): boolean {
  return !!value.match(/^0x[0-9A-Fa-f]*$/)
}

// Convert internal types to hex prefixed string, eg: '0x0a'
// Ensure we only convert values if we need them
export function hex(b: BufferTypes | HexTypes | Uint8Array | BigIntTypes | number | BigNumber) : PrefixedHexString {
  if(b == null) {
    return undefined;
  }
  if(Buffer.isBuffer(b)) {
    return '0x'+b.toString('hex');
  }
  if(b instanceof Uint8Array) {
    return '0x'+Buffer.from(b).toString('hex');
  }
  if(b instanceof BigNumber || (b as any)?._isBigNumber == true) {
    return (b as any).toHexString();
  }
  if(typeof b === 'string') {
    if(b.substring(0,2) !== '0x') throw new Error('invalid string');
    return b;
  }
  if(typeof b === 'bigint' || typeof b === 'number') {
    const value = b.toString(16);
    if (value.length % 2) { return ("0x0" + value); } // E.g: Case b < 16
    return "0x" + value;
  }
  
  debugger
  console.error({ b });
  throw new Error('unsupported');
}

export function expand32(b: BufferTypes) : Buffer32 {
  if(b == null) return undefined;
  if(b.length > 32) throw new Error('invalid');
  if(b.length == 32) return b;
  return Buffer.concat([ZERO_HASH_BUFFER.slice(0, 32-b.length), b]);
}

export function b32str(s: string) : Buffer32 {
  return buf(formatBytes32String(s));
}

export function bi(b: BufferTypes | BigIntTypes | Uint8Array | HexTypes | number | BigNumber | BN) : bigint {
  if(typeof b === 'bigint') return b;
  if(typeof b === 'number') return BigInt(b);
  if(b instanceof Buffer) return BigInt('0x'+b.toString('hex'));
  if(b instanceof Uint8Array) return bi(buf(b));
  if(typeof b === 'string' && b.substring(0,2) === '0x') return BigInt(b);
  if(BigNumber.isBigNumber(b)) return (b as BigNumber).toBigInt();
  if(BN.isBN(b)) {
    if(b.lt(new BN(0))) {
      throw new Error('- unsupported');
    }
    return BigInt('0x'+(b as BN).toString('hex'));
  }
  
  console.log(typeof b);
  console.log(b);
  debugger;
  throw new Error('unsupported');
}

export function nr(b: Buffer | Uint8Array | number | HexNumber | BigNumber | bigint | BN) : number {
  if(typeof b === 'number') return b;
  if(typeof b === 'bigint') {
    if(b > BigInt(Number.MAX_SAFE_INTEGER)) {
      throw new Error('out of range');
    }
    return Number(b);
  }
  if(BN.isBN(b)) {
    if(b.gt(new BN(Number.MAX_SAFE_INTEGER)) || b.lt(new BN(Number.MIN_SAFE_INTEGER))) {
      throw new Error('out of range');
    }
    return b.toNumber();
  }
  if(typeof b === 'string' || b instanceof Buffer || b instanceof Uint8Array) return Number(hex(b));
  if(b instanceof BigNumber) return b.toNumber();

  console.log(typeof b);
  console.log(b);
  debugger;
  throw new Error('unsupported');
}