import createStore from 'teaful';
import { ConfirmationFinalizedEvent } from '../contracts/confirmationsV1';
import { SettingConfiguredEvent } from '../contracts/oracleAuthorityV1';
import { FarmDepositIncreasedEvent, FarmExistsEvent, FarmMetastateEvent, RewardsHarvestedEvent } from '../contracts/referralFarmsV1';
import { ValidatedEntitlementEvent } from '../hooks/types';
import { ConfirmationEvent } from '../oracles/confirmationsV1';
import { FarmTokenSize } from '../oracles/farmTokenSizeV1';
import { ProofOfRecommendation, ProofOfRecommendationOrigin } from '../oracles/proofOfRecommendations';
import { LastConfirmationReward } from '../oracles/referralFarmsV1';
import { TokenListMap } from "../components/devtools/metrics/hooks/useFetchTokenList";

interface DataStore {
  isLoaded: boolean;

  // -- Contracts
  farmHashes: string[];

  settingsConfigured?: SettingConfiguredEvent[]; 
  farmExists: FarmExistsEvent[];
  farmExistsIdx?: Record<string, FarmExistsEvent>;
  confirmationsFinalized?: ConfirmationFinalizedEvent[];
  confirmationFinalizedIdx?: Record<string, ConfirmationFinalizedEvent>;
  farmDepositIncreased: FarmDepositIncreasedEvent[];
  farmMetastate?: FarmMetastateEvent[];
  rewardsHarvested?: RewardsHarvestedEvent[];
  rewardsHarvestedIdx?: Record<string, RewardsHarvestedEvent>;

  // -- Oracles
  confirmations?: ConfirmationEvent[];
  confirmationIdx?: Record<string, ConfirmationEvent>;
  entitlements: ValidatedEntitlementEvent[];

  // -- Rpc Fetched

  farmTokenSizes?: FarmTokenSize[];
  lastConfirmationRewards?: LastConfirmationReward[];

  proofOfRecommendations?: ProofOfRecommendation[];
  proofOfRecommendationOrigins?: ProofOfRecommendationOrigin[];

  tokenList: TokenListMap
}

// getStore can be used in services, useStore works as expected.
export const { useStore: useDataStore, getStore: getDataStore, withStore: withDataStore, setStore: setDataStore } = createStore<DataStore>({
  isLoaded: false,
  farmExists: [],
  farmHashes: [],

  entitlements: [],
  farmDepositIncreased: [],

  tokenList: new Map()
});