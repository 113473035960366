import useSWR from "swr";


import {useSession} from "../../../../stores/sessionStore";

import TOKEN_LIST from './tokenList.json';


export interface ERC20Token {
    address: string;
    chainId: number;
    name?: string;
    symbol?: string;
    decimals: number;
    logoURI?: string;
}

export type TokenListMap = Map<string, ERC20Token>;

export enum ChainId {
    Mainnet = 1,
    Rinkeby = 4,
}

export const TOKEN_LIST_CHAIN_NAME = (chainId) => ChainId[chainId] as keyof typeof ChainId;

async function erc20TokensFetcher(key: string, urls: string[]): Promise<{ tokens: ERC20Token[]; weight: number }[]> {
    try {
        const allRequest = urls.map(async (url) => {
            const res = await (await fetch(url)).json();

            return {
                tokens: res?.tokens || [],
                weight: 0,
            };
        });

        const allListResponse = await Promise.allSettled(allRequest);
        return allListResponse.map((res) => {
            if (res.status === 'fulfilled') {
                return res.value;
            }

            return {
                tokens: [],
                weight: 0,
            };
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export function useFetchTokenList() {
    const [chainId] = useSession.chainId();

    const tokenListUrl = TOKEN_LIST.ERC20[TOKEN_LIST_CHAIN_NAME(chainId)];
    const { data, error } = useSWR(() => ["erc20Tokens", tokenListUrl], erc20TokensFetcher);

    return {
        data,
        error
    }
};
