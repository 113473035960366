import createStore from 'teaful';
import { SettingConfiguredEvent } from "../contracts/oracleAuthorityV1";
import { getProvider } from '../helpers';
import { TraceableError } from '../types';

interface NetworkConfig {
  chainId: number;
  authority: string;
  oracles: string[];
  enableRequestCache: boolean;

  etherscanApiURL?: string;
  etherscanAPIKey?: string;
}

interface SessionStore {
  networks: Record<number, NetworkConfig>;

  chainId: number;
  authority?: string;
  farms?: string;
  confirmations?: string;
  developerSupportV1?: string;

  settingChanges?: SettingConfiguredEvent[];

  errors: TraceableError[];
}

// getStore can be used in services, useStore works as expected.
export const { useStore: useSession, getStore: getSession, withStore: withSession, setStore: setSession } = createStore<SessionStore>({
  errors: [],
  chainId: 0, // Default is 0, which mean no chain yet
  networks: {
    1: {
      chainId: 1,
      authority: '0x518173169DaB8483bc42c943969357A1F5BF091C',
      oracles: [
        'https://oracle-147-dub.attrace.com',
        // 'https://validation-oracle-147-fra.attrace.com',
      ],
      enableRequestCache: false,
      etherscanApiURL: 'https://api.etherscan.io/api',
      etherscanAPIKey: 'N9FIKRBTH61BM6JMQKQHYNFC6A43Q8M9DR',
    },
    4: {
      chainId: 4,
      authority: '0xe4517CE62Ee3f09Eee68940e3e2BcE27C525df4B',
      oracles: [
        'https://oracle-4470-dub.attrace.com',
      ],
      enableRequestCache: false,
      etherscanApiURL: 'https://api-rinkeby.etherscan.io/api',
      etherscanAPIKey: 'N9FIKRBTH61BM6JMQKQHYNFC6A43Q8M9DR',
    },
    5: {
      chainId: 5,
      authority: '0xDf1662F07678f070b09464C9C227d46789090fc8',
      oracles: [
        'https://oracle-5470-dub.attrace.com',
      ],
      enableRequestCache: false,
      etherscanApiURL: 'https://api-goerli.etherscan.io/api',
      etherscanAPIKey: 'N9FIKRBTH61BM6JMQKQHYNFC6A43Q8M9DR',
    },
    31337: {
      chainId: 31337,
      authority: '0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6',
      oracles: [
        'http://localhost:3000',
      ],
      enableRequestCache: false,
    },
  }
});

export async function getNetworkConfig() : Promise<NetworkConfig> {
  // We use native metamask
  const net = await getProvider().getNetwork();

  // Based on what is selected there, we select the right network from the store
  const [networks] = getSession.networks();
  const conf = networks[net.chainId];
  if(conf == null) {
    throw new Error(`Unknown network authority (chainId = ${net.chainId}), need to switch to Rinkeby or other supported network in Metamask`);
  }
  return conf;
}