import { BigNumber, Contract, ethers, Event } from "ethers";
import { defaultAbiCoder } from "ethers/lib/utils";
import { useState } from "react";
import useSWR from "swr/immutable";
import { Hex32 } from "../../../oracle/src/lib/types";
import { AuthorityConfig, hashPath, toHumanPath } from '../../../oracle/src/state/state';
import { getProvider } from "../helpers";
import { getNetworkConfig, setSession } from "../stores/sessionStore";

const authorityIface = new ethers.utils.Interface([
  "function settingValidator() view returns (address)",
  `function changeCount() view returns (uint256)`,
  `function getChange(uint256 idx) view returns ((bytes32[] path, uint64 releaseTime, bytes value) change)`,

  "event SettingConfigured(bytes32 indexed path0, bytes32 indexed pathIdx, bytes32[] path, uint64 releaseTime, bytes value)",
  "event PendingSettingCancelled(bytes32 indexed path0, bytes32 indexed pathIdx, (bytes32[] path, uint64 releaseTime, bytes value) setting)",
]);

export async function getAuthority() : Promise<Contract> {
  const netConfig = await getNetworkConfig();
  return new ethers.Contract(netConfig.authority, authorityIface, getProvider());
}

export interface SettingConfiguredEvent {
  path: Hex32[];
  fmtPath: string;
  releaseTime: number;
  value: string;
  event: Event;
}

export async function getSettingConfiguredEvents() : Promise<SettingConfiguredEvent[]> {
  const authority = await getAuthority();
  const events = await authority.queryFilter(authority.filters.SettingConfigured());
  return events.map(e => {
    return {
      fmtPath: toHumanPath(e.args.path),
      path: e.args.path,
      releaseTime: e.args.releaseTime.toNumber(),
      value: e.args.value,
      event: e,
    }
  });
}

export async function scanChanges() : Promise<SettingConfiguredEvent[]> {
  const changes = [];
  const authority = await getAuthority();
  const changeCount: BigNumber = (await authority.changeCount() as BigNumber);
  for(let i = 0; i < changeCount.toNumber(); i++) {
    const change = await authority.getChange(i);
    changes.push(change);
  }
  return changes;
}

export async function setAuthorityConfigs() {
  const net = await getNetworkConfig();
  setSession.authority(net.authority);
  setSession.chainId(net.chainId);

  const changes = await scanChanges();
  for(let change of changes) {
    if(hashPath(change.path).equals(AuthorityConfig.referralFarmsV1.pathKey)) {
      setSession.farms(parseConfigValue(AuthorityConfig.referralFarmsV1, change.value));
    }
    if(hashPath(change.path).equals(AuthorityConfig.confirmationsV1.pathKey)) {
      setSession.confirmations(parseConfigValue(AuthorityConfig.confirmationsV1, change.value));
    }
    if (hashPath(change.path).equals(AuthorityConfig.developerSupportV1.pathKey)) {
      setSession.developerSupportV1(parseConfigValue(AuthorityConfig.developerSupportV1, change.value));
    }
  }

  setSession.settingChanges(changes);
}

function parseConfigValue(config: AuthorityConfig, value: any) : any {
  return defaultAbiCoder.decode([config.abiFormat], value)[0];
}

interface Setting {
  path: Hex32[];
  releaseTime: number;
  value: string;
}

export interface PendingSettingCancelledEvent {
  fmtPath: string;
  path0: Hex32;
  pathIdx: Hex32;
  setting: Setting;
  event: Event;
}

export async function pendingSettingCancelledEventsFetcher() : Promise<PendingSettingCancelledEvent[]> {
  const contract = await getAuthority();
  const events = await contract.queryFilter(contract.filters.PendingSettingCancelled());
  return events.map(e => {
    return {
      fmtPath: toHumanPath(e.args.setting.path),
      path0: e.args.path0,
      pathIdx: e.args.pathIdx,
      setting: {
        path: e.args.setting.path,
        releaseTime: e.args.setting.releaseTime.toNumber(),
        value: e.args.setting.value,
      },
      event: e,
    }
  });
}

export function usePendingSettingCancelledEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['authority.pendingSettingCancelled'] : null, pendingSettingCancelledEventsFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data, 
    error,
  }
}