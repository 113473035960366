import useSWR from 'swr';

const URL = "https://improve.attrace.com/server/visits.php"

export interface Visitor {
  id: number;
  datetime: string;
  domain: string;
  page: string;
  referrer: string;
}

async function visitorsFetcher() : Promise<Visitor[]> {
  const res = await fetch(URL).then((res) =>
    res.json()
  );

  return res
}


export function useFetchVisitors() {
  const { data, error } = useSWR(() => ["visits"], visitorsFetcher);

  return {
    data, 
    error,
  }
}