import React, { useEffect, useState } from 'react';
import { fmtTime } from '../lib/time';
import { useSession } from '../stores/sessionStore';
import { useUiContext } from '../stores/uiContextStore';

export function SessionView() {
  const [numberPrecision, setNumberPrecision] = useUiContext.numberPrecision();
  const [units, setUnits] = useUiContext.units();
  const [timeFormat, setTimeFormat] = useUiContext.timeFormat();
  const [cacheMinutes] = useUiContext.cacheMinutes();
  const [chainId] = useSession.chainId();
  const [networks] = useSession.networks();
  const [localTime, setLocalTime] = useState('');
  
  useEffect(() => {
    let interval = setInterval(() => {
      setLocalTime(fmtTime(Date.now()));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function setter(e) {
    let num = parseInt(e.target.value);
    if (!num || isNaN(num)) return;
    if (num < 0) num = 0;
    setNumberPrecision(num);
  }

  function clearCache() {
    if (confirm('Clear cache?')) {
      localStorage.clear();
      console.log('cache:cleared');
    }
  }

  const isCaching = chainId && networks && networks[chainId] && networks[chainId].enableRequestCache;

  return (
    <div className="text-right inline-block align-middle text-xs">
      <div className="flex flex-auto">
        <div className="flex flex-auto pt-1">
          <div className='mr-4'>
            <input type="number" title='precision' placeholder='precision' className="border w-10" value={numberPrecision} onChange={setter} />
          </div>
          <div className='mr-4'>
            <input type="number" title='units' placeholder='units' className="border w-10" value={units} onChange={e => setUnits(parseInt(e.target.value))} />
          </div>
          <div className='mr-4'>
            <span className="text-xs">UTC: {localTime}</span><br/>
            <input type="text" title='time' placeholder='time' className="border w-48" value={timeFormat} onChange={e => setTimeFormat(e.target.value)} />
          </div>
        </div>
        {isCaching && (
          <button className={`bg-gray-100 border-gray border hover:bg-gray-400 text-black pr-4 pl-4 text-xs`} onClick={_ => clearCache()}>
            Clear Cache <span className='text-xs italic'>{cacheMinutes}min</span>
          </button>
        )}
      </div>
    </div>
  );
}