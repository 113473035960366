import { FormatTypes, Interface } from "@ethersproject/abi";
import { ethers, Event } from "ethers";
import { hex } from "../../../oracle/src/lib/buffer";
import { Hex32 } from "../../../oracle/src/lib/types";
import { toReactorAddress } from '../../../oracle/src/rpc/lib/address';
import { fmtTime } from "../lib/time";
import { rpcRequest, RpcRoute } from "./rpcHelpers";

const iface = new Interface([
  `event Confirmation(bytes32 indexed hash, uint256 indexed number, bytes32 stateRoot, bytes32 parentHash, uint64 timestamp, bytes32 bundleHash, bytes32 indexed closerHash, uint32 blockCount, uint64 confirmChainBlockNr)`,
]);

export interface ConfirmationEvent {
  hash: Hex32;
  blockHash: Hex32;
  number: number;
  stateRoot: Hex32;
  parentHash: Hex32;
  fmtTime: string;
  timestamp: number;
  bundleHash: Hex32;
  closerHash: Hex32;
  blockCount: number;
  confirmChainBlockNr: number;
  event: Event;
  log: any;
}

export async function getConfirmationEvents() : Promise<ConfirmationEvent[]> {
  const params = [{
    address: hex(toReactorAddress('confirmationV1Reactor')),
    topics: [
      ethers.utils.id(iface.getEvent('Confirmation').format(FormatTypes.sighash)),
    ],
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);
  const events: ConfirmationEvent[] = [];

  // Parse events
  for(let log of logs) {
    const event = iface.parseLog(log);
    events.push({
      blockHash: log.blockHash,
      hash: event.args.hash,
      number: event.args.number.toNumber(),
      stateRoot: event.args.stateRoot,
      parentHash: event.args.parentHash,
      fmtTime: fmtTime(event.args.timestamp.toNumber()),
      timestamp: event.args.timestamp.toNumber(),
      bundleHash: event.args.bundleHash,
      closerHash: event.args.closerHash,
      blockCount: event.args.blockCount,
      confirmChainBlockNr: event.args.confirmChainBlockNr.toNumber(),
      event,
      log,
    });
  }

  return events;
}