import { useFilteredData, useFilteredFarmHashes } from "../hooks/data";
import { useUiContext } from "../stores/uiContextStore";
import ArchitectureModal from "./architecture";
import BlockChainView from "./blockChainView";
import ControlBar from "./controlBar";
import DevTools from "./devtools";
import ErrorStack from "./errorStack";
import FarmOuterView from "./farmOuterView";
import InspectDataStore from "./inspectDataStore";
import RouteState from "./routeState";
import { SessionView } from "./sessionView";
import { ShowOracle } from "./showOracle";
import Tools from "./tools";
import TransfersView from "./transferView";

// High level collects all data into a pre-configured pivot table
export default function Oversight() {
  const { isLoaded } = useFilteredData();
  const { ensure, data: filteredFarmHashes, error } = useFilteredFarmHashes();
  // const [showFarms] = useUiContext.showFarms();
  // const [showExplorer] = useUiContext.showExplorer();
  // const [showArch, setShowArch] = useUiContext.showArch();
  const [showTools] = useUiContext.showTools();
  // const [showBlocks] = useUiContext.showBlocks();
  // const [showTransfers] = useUiContext.showTransfers();
  const [showDevtools] = useUiContext.showDevtools();

  if(error) {
    throw error;
  }

  return (
    <div className="text-sm pl-1 pr-1 min-h-[3000px] ">
      <div className="flex">
        {/* <ControlBar /> */}
        <SessionView />
      </div>

      {/* {isLoaded && (
        <div>
          <ShowOracle />
          {showFarms && filteredFarmHashes && filteredFarmHashes.map(fh => <FarmOuterView key={`fov-${fh}`} farmHash={fh} />)}
          {showExplorer && <InspectDataStore />}
        </div>
      ) || <>Loading data sets...</>}
      
      
      {showTools && <Tools />}
      
      {showArch && <ArchitectureModal hide={e => setShowArch(false)} />}

      {showBlocks && <BlockChainView />}
      {showTransfers && <TransfersView />} */}

      <DevTools />
      
{/* 
      <RouteState />
      <ErrorStack /> */}
    </div>
  )
}