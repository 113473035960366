import { FormatTypes, Interface } from "@ethersproject/abi";
import { ethers, Event } from "ethers";
import { useState } from "react";
import useSWR from "swr/immutable";
import { hex } from "../../../oracle/src/lib/buffer";
import { Hex20, Hex24, Hex32 } from "../../../oracle/src/lib/types";
import { toReactorAddress } from "../../../oracle/src/rpc/lib/address";
import { fmtTime } from "../lib/time";
import { rpcCall, rpcRequest, RpcRoute } from "./rpcHelpers";

const iface = new Interface([
  'function getPositionSize(bytes32 farmHash, bytes24 referredToken, address holder) view returns (uint128)',
  'function getNegativeBalance(bytes24 referredToken, address holder) view returns (uint128)',

  `event FarmPositionIncreased(bytes32 indexed farmHash, bytes24 indexed token, address indexed holder, uint256 value, uint64 time, address origin, bytes32 tx, uint32 logIdx)`,
  `event FarmPositionDecreased(bytes32 indexed farmHash, bytes24 indexed token, address indexed holder, uint256 value, uint64 time, bytes32 tx, uint32 logIdx)`,
  `event FarmPositionJoined(bytes32 indexed farmHash, bytes24 indexed token, address indexed secondary, address holder, uint256 value, uint64 time)`,

  `event FarmPositionSnapshot(bytes32 indexed farmHash, bytes24 indexed token, address indexed holder, uint256 value, uint64 time)`,
]);

export interface FarmPositionJoinedEvent {
  farmHash: Hex32;
  holder: Hex20;
  secondary: Hex20;
  time: number;
  fmtTime: string;
  token: Hex24;
  value: bigint;
  event: Event;
  log: any;
}

async function farmPositionJoinedFetcher() : Promise<FarmPositionJoinedEvent[]> {
  const params = [{
    address: hex(toReactorAddress('farmPositionsV1Reactor')),
    topics: [
      ethers.utils.id(iface.getEvent('FarmPositionJoined').format(FormatTypes.sighash)),
    ],
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);

  return logs.map(log => {
    const event = iface.parseLog(log);

    return {
      farmHash: event.args.farmHash,
      holder: event.args.holder,
      secondary: event.args.secondary,
      token: event.args.token,
      time: event.args.time.toNumber(),
      fmtTime: fmtTime(event.args.time.toNumber()),
      value: event.args.value.toBigInt(),
      event,
      log,
    }
  });
}

export function useFarmPositionJoinedEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['farmPositionJoined'] : null, farmPositionJoinedFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data: data || [], 
    error,
  }
}

export interface FarmPositionIncreasedEvent {
  farmHash: Hex32;
  token: Hex24;
  holder: Hex20;
  value: bigint;
  time: number;
  fmtTime: string;
  origin: Hex20;
  tx: Hex32;
  logIdx: number;
  event: Event;
  log: any;
}

async function farmPositionIncreasedFetcher() : Promise<FarmPositionIncreasedEvent[]> {
  const params = [{
    address: hex(toReactorAddress('farmPositionsV1Reactor')),
    topics: [
      ethers.utils.id(iface.getEvent('FarmPositionIncreased').format(FormatTypes.sighash)),
    ],
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);

  // Parse events
  return logs.map(log => {
    const event = iface.parseLog(log);
    return {
      farmHash: event.args.farmHash,
      token: event.args.token,
      holder: event.args.holder,
      value: event.args.value.toBigInt(),
      time: event.args.time.toNumber(),
      fmtTime: fmtTime(event.args.time.toNumber()),
      origin: event.args.origin,
      tx: event.args.tx,
      logIdx: event.args.logIdx,
      event,
      log,
    }
  });
}

export function useFarmPositionIncreasedEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['farmPositionIncreased'] : null, farmPositionIncreasedFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data: data || [], 
    error,
  }
}

export interface FarmPositionDecreasedEvent {
  farmHash: Hex32;
  token: Hex24;
  holder: Hex20;
  value: bigint;
  time: number;
  fmtTime: string;
  tx: Hex32;
  logIdx: number;
  event: Event;
  log: any;
}

async function farmPositionDecreasedFetcher() : Promise<FarmPositionDecreasedEvent[]> {
  const params = [{
    address: hex(toReactorAddress('farmPositionsV1Reactor')),
    topics: [
      ethers.utils.id(iface.getEvent('FarmPositionDecreased').format(FormatTypes.sighash)),
    ],
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);

  // Parse events
  return logs.map(log => {
    const event = iface.parseLog(log);
    return {
      farmHash: event.args.farmHash,
      token: event.args.token,
      holder: event.args.holder,
      value: event.args.value.toBigInt(),
      time: event.args.time.toNumber(),
      fmtTime: fmtTime(event.args.time.toNumber()),
      tx: event.args.tx,
      logIdx: event.args.logIdx,
      event,
      log,
    }
  });
}

export function useFarmPositionDecreasedEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['farmPositionDecreased'] : null, farmPositionDecreasedFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data: data || [], 
    error,
  }
}

export interface FarmPositionSnapshotEvent {
  farmHash: Hex32;
  token: Hex24;
  holder: Hex20;
  value: bigint;
  time: number;
  fmtTime: string;
  event: Event;
  log: any;
}

async function farmPositionSnapshotFetcher() : Promise<FarmPositionSnapshotEvent[]> {
  const params = [{
    address: hex(toReactorAddress('farmPositionsV1Reactor')),
    topics: [
      ethers.utils.id(iface.getEvent('FarmPositionSnapshot').format(FormatTypes.sighash)),
    ],
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);

  // Parse events
  return logs.map(log => {
    const event = iface.parseLog(log);
    return {
      farmHash: event.args.farmHash,
      token: event.args.token,
      holder: event.args.holder,
      value: event.args.value.toBigInt(),
      time: event.args.time.toNumber(),
      fmtTime: fmtTime(event.args.time.toNumber()),
      event,
      log,
    }
  });
}

export function useFarmPositionSnapshotEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['farmPositionSnapshot'] : null, farmPositionSnapshotFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data: data || [], 
    error,
  }
}

// -- RPC calls

export async function getPositionSize(farmHash: Hex32, referredToken: Hex24, holder: Hex20) : Promise<bigint> {
  const data = iface.encodeFunctionData('getPositionSize', [farmHash, referredToken, holder]);
  return BigInt(await rpcCall(RpcRoute.rpc, hex(toReactorAddress('farmPositionsV1Reactor')), data));
}

export interface TokenPosition {
  farmHash: Hex32;
  token: Hex24;
  holder: Hex20;
  size: bigint;
}

export async function getPositionSizes(events: FarmPositionJoinedEvent[]) : Promise<TokenPosition[]> {
  const uniques = {};
  const res: TokenPosition[] = [];
  for(let { farmHash, token, holder } of events) {
    const key = `${farmHash}|${token}|${holder}`;
    if(uniques[key]) continue;
    const size = await getPositionSize(farmHash, token, holder);
    res.push({
      farmHash,
      token,
      holder,
      size,
    })
    uniques[key] = true;
  }
  return res;
} 

export function calcFarmPositionCount(events: FarmPositionJoinedEvent[], farmHashSearch: Hex32) : number {
  const idx = {};
  for(let { farmHash, holder } of events) {
    if(farmHash !== farmHashSearch) continue;
    if(!idx[holder]) idx[holder] = true;
  }
  return Object.keys(idx).length;
}

export function calcFarmPositionSize(tokenPositions: TokenPosition[], farmHashSearch: Hex32) : bigint {
  let total = 0n;
  for(let { farmHash, size } of tokenPositions) {
    if(farmHash !== farmHashSearch) continue;
    total += size;
  }
  return total;
}