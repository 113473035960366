import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { setAuthorityConfigs } from './contracts/oracleAuthorityV1';
import Oversight from './components/oversight';

// Add json formatting to BigInt
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

export function App() {
  
  // App initialization
  useEffect(() => {
    setAuthorityConfigs();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Oversight />} />
      </Routes>
    </BrowserRouter>
  );
}
