import { useDataStore } from "../../../../stores/dataStore";
import PromoterMetricsView from "./promoterMetricsView";

export function PromoterMetrics({ textFilter }) {
  const [isLoaded] = useDataStore.isLoaded();

  if (!isLoaded) return (<div><h4>Metrics loading...</h4></div>)

  return (
    <div>
      <h2>Metrics per Promoter</h2>
      <PromoterMetricsView textFilter={textFilter} />
    </div>
  )
}
