import { parseBytes32String } from "ethers/lib/utils";
import { useState } from "react";
import useSWR from "swr/immutable";
import { nr } from "../../../oracle/src/lib/buffer";
import { rpcEncode } from '../../../oracle/src/lib/rpc';
import { Hex20, Hex32 } from "../../../oracle/src/lib/types";
import { fmtTime } from "../lib/time";
import { rpcRequest, RpcRoute } from "./rpcHelpers";

export interface ProofOfRecommendation {
  signer: Hex20;
  token: Hex20;
  referrer: Hex20;
  router: Hex20;
  dapp: Hex32;
  time: number;
  fmtTime: string;
  req: string;
  sig: string;
  timePromises: string;
  fmtDapp: string;
}

async function recommendationsFetcher(ts: number = 1) : Promise<ProofOfRecommendation[]> {
  const rows = await rpcRequest(RpcRoute.recommendations, 'dev_scanProofOfRecommendations', [rpcEncode(ts)]);
  return rows.map(r => {
    const { signer, token, referrer, router, dapp, time, req, sig, timePromises } = r;
    return {
      fmtDapp: parseBytes32String(dapp),
      time: nr(time),
      fmtTime: fmtTime(nr(time)),
      signer,
      token,
      referrer,
      router,
      dapp,
      req,
      sig,
      timePromises,
    }
  })
}

export function useScanProofOfRecommendations(ts: number = 1) {
  const [shouldFetch, ensure] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? [ts, 'dev_scanProofOfRecommendations'] : null, recommendationsFetcher);

  return {
    ensure,
    data, 
    error,
  }
}

export interface ProofOfRecommendationOrigin {
  signer: Hex20;
  token: Hex20;
  router: Hex20;
  time: number;
  fmtTime: string;
  sig: string;
  timePromises: string;
}

async function originsFetcher(ts: number = 1) : Promise<ProofOfRecommendationOrigin[]> {
  const rows = await rpcRequest(RpcRoute.recommendations, 'dev_scanProofOfRecommendationOrigins', [rpcEncode(ts)]);
  return rows.map(r => {
    const { signer, token, router, time, sig, timePromises } = r;
    return {
      time: nr(time),
      fmtTime: fmtTime(nr(time)),
      signer,
      token,
      router,
      sig,
      timePromises,
    }
  })
}

export function useScanProofOfRecommendationOrigins(ts: number = 1) {
  const [shouldFetch, ensure] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? [ts, 'dev_scanProofOfRecommendationOrigins'] : null, originsFetcher);

  return {
    ensure,
    data, 
    error,
  }
}
